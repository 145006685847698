import { Component } from 'react';

import isEmpty from 'Lib/utilities/isEmpty';

class Text extends Component {
    returnEmptyText = (rest, emptyText) => {
        return <span {...rest}>{emptyText}</span>;
    };

    render() {
        // helperText is omitted to prevent passing through
        const { value, emptyText = '--', defaultValue, nl2br = false, helperText, ...rest } = this.props;
        const val = isEmpty(value) ? defaultValue : value;

        // put this in here so that we can use this with any other text rendering component.
        // that way we dont need to do the empty check in every component.
        if (this.props.children) {
            if (this.props.children.props.value) {
                return <span {...rest}>{this.props.children}</span>;
            } else {
                return this.returnEmptyText(rest, emptyText);
            }
        }

        if (isEmpty(val)) {
            return this.returnEmptyText(rest, emptyText);
        }

        if (nl2br) {
            return (
                <span>
                    {val.split('\n').map((text, textIndex) => (
                        <span key={textIndex}>
                            {text}
                            <br />
                        </span>
                    ))}
                </span>
            );
        }

        return <span {...rest}>{val}</span>;
    }
}

export default Text;
