import { Fragment, useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import Badge from 'reactstrap/lib/Badge';
import Input from 'reactstrap/lib/Input';
import PropTypes from 'prop-types';

import Card from 'Lib/components/common/Card';
import DateTime from 'Lib/components/common/DateTime';
import Form from 'Lib/components/form/Form';
import TextAreaField from 'Lib/components/form/TextAreaField';
import PortalTypes from 'Lib/constants/app/portalTypes';
import ChatRoleTypes, { ChatOptions, getInitialChatValues, getValues, renderSharedWithText } from 'Lib/constants/communications/chatRoleTypes';
import useApi from 'Lib/hooks/useApi';
import useInterval from 'Lib/hooks/useInterval';
import debounce from 'Lib/utilities/debounce';
import isEmpty from 'Lib/utilities/isEmpty';
import openExternalLink from 'Lib/utilities/openExternalLink';

import Spinner from '../common/Spinner'; 
import Text from '../common/Text';
import { UploadFiles } from '../files/UploadFiles';
import ChecklistField from '../form/ChecklistField';

import 'Lib/scss/components/_chat.scss';

export const STATS_INTERVAL_TIMER = 45000; // Call stats for unread message count every 45 seconds

export const ChatTab = ({ asNav = false, unreadCount = 0 }) => {
    return asNav ? (
        <div className="chat-nav-menu-item">
            <div className="chat-label">Chat</div>
            {unreadCount ? <span className="chat-notification">{unreadCount}</span> : null}
        </div>
    ) : (
        <div className="chat-tab-item">
            <span>Chat</span>
            {unreadCount ? <span className="chat-notification">{unreadCount}</span> : null}
        </div>
    );
};

const Chat = props => {
    const [isLoading, setLoading] = useState(true);
    const [isSending, setSending] = useState(false);
    const [shouldScrollToBottom, setShouldScrollToBottom] = useState(true);

    const [page, setPage] = useState(1);
    const [messages, setMessages] = useState([]);
    const [chatPagination, setChatPagination] = useState({});

    const fetchMessages = useApi(props.api.get);
    const fetchStats = useApi(props.api.stats);
    const createMessage = useApi(props.api.create);
    const deleteMessage = useApi(props.api.delete);

    useEffect(() => {
        handleGet();
    }, [page]);

    useInterval(() => {
        let mounted = true;

        if (mounted) handleStats();

        return function cleanup() {
            mounted = false;
        };
    }, STATS_INTERVAL_TIMER);

    const handleStats = () => {
        if (props.id) {
            fetchStats(props.id).then(response => {
                if (response.data.unreadCount > 0) {
                    props?.setUnreadCount?.(response.data.unreadCount);
                    handleGet();
                }
            });
        } else {
            fetchStats({ chatObjectTypeID: props.chatObjectTypeID, objectID: props.objectID }).then(response => {
                if (response.data.unreadCount > 0) {
                    props?.setUnreadCount?.(response.data.unreadCount);
                    handleGet();
                }
            });
        }
    };

    const handleOnChange = debounce(text => {
        handleGet(text);
    }, 300);

    const handleDuplicateArrays = (response, event) => {
        const allMessages = event ? [...response.data] : [...messages, ...response.data];
        const uniqueChatMessageIDs = Array.from(new Set(allMessages.map(a => a.message.messageID)));
        let uniqueChats = uniqueChatMessageIDs.map(id => allMessages.find(a => a.message.messageID === id));
        uniqueChats = sortChats(uniqueChats);
        return uniqueChats;
    };

    const sortChats = chatsArray => {
        return chatsArray?.sort((a, b) => new Date(a.message.dateTimeCreated) - new Date(b.message.dateTimeCreated));
    };

    const handleGet = event => {
        let search = null;

        if (event) {
            search = event;
        }

        if (props.id) {
            fetchMessages(props.id, { page }).then(response => {
                // Sort Messages by most recently sent
                setMessages(handleDuplicateArrays(response, event));
                setLoading(false);
                setChatPagination({
                    pageSize: response.headers['pagination-page-size'],
                    currentPage: response.headers['pagination-current-page'],
                    totalItems: response.headers['pagination-total-items'],
                    totalPages: response.headers['pagination-total-pages;'],
                    lastPage: response.headers['pagination-last-page']
                });
                setPage(chatPagination.currentPage);
            });
        } else {
            fetchMessages({ chatObjectTypeID: props.chatObjectTypeID, objectID: props.objectID, page, search }).then(response => {
                setMessages(handleDuplicateArrays(response, event));
                setLoading(false);
                setChatPagination({
                    pageSize: response.headers['pagination-page-size'],
                    currentPage: response.headers['pagination-current-page'],
                    totalItems: response.headers['pagination-total-items'],
                    totalPages: response.headers['pagination-total-pages;'],
                    lastPage: response.headers['pagination-last-page']
                });
            });
        }
    };

    const handleCreate = (values, form) => {
        setSending(true);
        const data = getValues(props.portalTypeID, values);

        if (props.id) {
            createMessage(props.id, data)
                .then(() => {
                    handleGet();
                    setSending(false);
                    props.callback?.();
                    form.setSubmitting(false);
                    form.setFieldValue('attachments', null);
                    form.setFieldValue('message', null);
                })
                .catch(error => {
                    setSending(false);
                    form.setSubmitting(false);
                    props.notify({ error });
                });
        } else {
            createMessage(data)
                .then(() => {
                    handleGet();
                    setSending(false);
                    props.callback?.();
                    form.setSubmitting(false);
                    form.setFieldValue('attachments', null);
                    form.setFieldValue('message', null);
                })
                .catch(error => {
                    setSending(false);
                    form.setSubmitting(false);
                    props.notify({ error });
                });
        }
    };

    const handleDelete = messageID => {
        deleteMessage(messageID).then(() => {
            handleGet();
            setLoading(false);
        });
    };

    const handleScroll = e => {
        const element = e.target;

        if (element.scrollTop === 0 && chatPagination.totalItems > messages.length && page !== parseInt(chatPagination.totalPages)) {
            setPage(page + 1);
            setShouldScrollToBottom(false);
        }
    };

    return (
        <Messages
            {...props}
            isLoading={isLoading}
            isSending={isSending}
            messages={messages}
            handleCreate={handleCreate}
            handleDelete={handleDelete}
            handleScroll={handleScroll}
            handleOnChange={handleOnChange}
            shouldScrollToBottom={shouldScrollToBottom}
        />
    );
};

const Messages = props => {
    const [ref, setRef] = useState(null);

    const hideOptions = props.hideOptions || false;
    let messages = props.messages || [];
    const noMessages = isEmpty(messages);

    const scrollToBottom = () => ref?.scrollIntoView(false);

    useEffect(() => {
        if (props.shouldScrollToBottom) {
            scrollToBottom();
        }
    }, [ref, messages]);

    if (messages) {
        messages = messages.map(({ message, user }, index) => {
            const isCurrentUser = user.userID == props.userID;

            return (
                <Fragment key={`${message.messageID}_${index}`}>
                    <div
                        key={message.messageID}
                        className="message"
                        style={{
                            alignSelf: isCurrentUser ? 'flex-end' : 'flex-start',
                            background: message.isRead == '0' ? '#f3f9ff' : '#ffffff',
                            border: message.isRead == '0' ? '1px solid #a8a7ff' : '1px solid #ccc'
                        }}
                    >
                        {/* Message header that contains user, type, and dateTime */}
                        <div className="message-header">
                            <div className="message-contact">
                                <div className="message-user">
                                    <Text value={`${user.firstName} ${user.lastName}`} />
                                </div>
                                <Badge style={{ color: '#454545', margin: '3px', backgroundColor: ChatRoleTypes.getProperty(message.roleTypeID, 'color') }}>
                                    {ChatRoleTypes.getName(message.roleTypeID)}
                                </Badge>
                            </div>
                            <em className="message-datetime">
                                <DateTime value={message.dateTimeCreated} />
                            </em>
                        </div>

                        {/* Content of Message */}
                        <div className="message-content-container">
                            <div className="message-content">
                                <Text value={message.message} nl2br={true} />
                            </div>

                            {props.objectType == message.roleTypeID && (
                                <div>
                                    <i className="fas fa-trash-alt" onClick={() => props.handleDelete(message.workOrderMessageID)} style={{ cursor: 'pointer' }} />
                                </div>
                            )}
                        </div>

                        {/* Footer which contains file attachments */}
                        {!isEmpty(message.fileAttachments) && (
                            <div className="message-footer">
                                <hr />
                                <em>Attachments</em>
                                <br />
                                <i className="fa fa-link" aria-hidden="true" />
                                {'  '}
                                {message.fileAttachments.map((file, fileIndex) => (
                                    <a
                                        key={fileIndex}
                                        style={{ paddingRight: '2vw' }}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={props.filePreview(
                                            message.messageID,
                                            props.portalTypeID == PortalTypes.Manager ? file.fileID : file.fileAttachmentID,
                                            message.objectID
                                        )}
                                    >
                                        {file.title}
                                    </a>
                                ))}
                            </div>
                        )}
                    </div>

                    {/* Shared With Text below Message */}
                    <div className="message-shared-with" style={{ display: 'flex', paddingBottom: '1vh', alignSelf: isCurrentUser ? 'flex-end' : 'flex-start' }}>
                        <em>{renderSharedWithText(props.portalTypeID, message)}</em>
                    </div>
                </Fragment>
            );
        });
    }

    return (
        <Card
            title={props.title || 'Chat'}
            renderHeader={() => {
                return (
                    <div className="card-header" style={{ justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
                        <div>{props.title || 'Chat'}</div>
                        <div className="card-header-actions" style={props.portalTypeID == PortalTypes.Manager ? { display: 'flex', flex: '0.1 1 auto', gap: '5%' } : {}}>
                            <Input type="search" placeholder="Search..." onChange={e => props.handleOnChange(e.target.value)} />
                            {props.portalTypeID == PortalTypes.Manager && (
                                <Button
                                    style={{ width: '100%' }}
                                    onClick={() => openExternalLink(`/api/manager/chat/messages/print/${props.chatObjectTypeID}/${props.objectID}`)}
                                    color="action"
                                    size="sm"
                                >
                                    Print Chat
                                </Button>
                            )}
                        </div>
                    </div>
                );
            }}
        >
            <div
                className="chat-container"
                onScroll={props.handleScroll}
                style={{
                    height: noMessages ? '12vh' : 'auto',
                    maxHeight: noMessages ? 'none' : '45vh'
                }}
            >
                {props.isLoading ? (
                    <Spinner />
                ) : noMessages ? (
                    <div className="empty-chat">No messages</div>
                ) : (
                    <>
                        {messages}
                        <div ref={messageRef => setRef(messageRef)} />
                    </>
                )}
            </div>
            <br />
            <Form
                initialValues={{
                    chatObjectTypeID: props.chatObjectTypeID,
                    objectID: props.objectID,
                    ...getInitialChatValues(props.portalTypeID)
                }}
                onSubmit={props.handleCreate}
                render={form => {
                    const isSendDisabled = form.isSubmitting || props.isSending || isEmpty(form.values.recipients) || isEmpty(form.values.message);

                    return (
                        <form onSubmit={form.handleSubmit}>
                            <div className={props.isLoading ? 'disabled-div' : ''}>
                                {!hideOptions && (
                                    <ChecklistField
                                        isClearable={false}
                                        menuPlacement="top"
                                        id="recipients"
                                        name="recipients"
                                        label="Send message to"
                                        options={ChatOptions(props.portalTypeID)}
                                    />
                                )}
                                <div className="chat-controls">
                                    <div className="chat-message">
                                        <TextAreaField id="message" name="message" placeHolder="New message..." label="Message" />
                                        <UploadFiles
                                            key={props.isSending}
                                            api={{ upload: props.api.uploadFile, delete: props.api.deleteFile }}
                                            formObject={{ form, field: 'attachments' }}
                                        />
                                    </div>
                                    <div className="chat-send">
                                        <Button disabled={isSendDisabled} color="primary" type="submit">
                                            Send{'  '}
                                            <i className="fal fa-paper-plane" />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    );
                }}
            />
        </Card>
    );
};

Chat.propTypes = {
    api: PropTypes.object.isRequired,
    userID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    objectID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    chatObjectTypeID: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default Chat;
