import { Component } from 'react';
import { NumericFormat } from 'react-number-format';

class Percent extends Component {
    render() {
        const { id, className, prefix, required, emptyText, suffix = '%', decimalScale = 2, fixedDecimalScale = true, value } = this.props;
        const attrs = {
            className,
            decimalScale,
            fixedDecimalScale,
            id,
            prefix,
            required,
            suffix,
            thousandSeparator: ',',
            value
        };

        if (!value || Math.ceil(value) < 1) {
            return emptyText ? emptyText : null;
        }

        return <NumericFormat {...attrs} displayType="text" />;
    }
}

export default Percent;
