import { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { Icon, Tooltip, Box } from '@mui/material';
import classnames from 'classnames';

import { isFunction } from 'Lib/utilities/isFunction';

class Card extends Component {
    openExternal = url => {
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.rel = 'noopener noreferrer';
        anchor.target = '_blank';
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    };

    renderActions = () => {
        const { actions, dropdownText = 'Actions', renderActions, actionMenuIcon } = this.props;
        let action = null;
        let actionList = [];

        if (isFunction(renderActions)) return renderActions();
        if (!actions) return;

        if (isFunction(actions)) {
            actionList = actions();
        } else {
            actionList = actions;
        }

        if (!actionList || actionList === null || actionList.length == 0) return;

        if (actionList.length == 1) {
            action = actionList[0];

            if (action.to) {
                if (action.external) {
                    return (
                        <Link outline={action.outline} to={action.to} style={{ ...action.sx }} target="_blank">
                            <Button color="action" size="sm" variant="contained">
                                {action.label}
                            </Button>
                        </Link>
                    );
                }

                return (
                    <Link outline={action.outline} to={action.to} style={{ ...action.sx }}>
                        <Button color="action" size="sm" disabled={action.isLoading}>
                            {action.icon ? (
                                <>
                                    <i className={`${action.icon}`}></i>&nbsp;&nbsp;
                                </>
                            ) : null}
                            {action.label}
                        </Button>
                    </Link>
                );
            }

            if (action.onClick) {
                return (
                    <Button
                        outline={action.outline}
                        onClick={action.onClick}
                        color="action"
                        size="sm"
                        disabled={action.isLoading || action.disabled}
                        style={{ border: 'none', outline: 'none', ...action.sx }}
                    >
                        {action.icon ? <Icon>{action.icon}</Icon> : null}
                        {action.label}
                    </Button>
                );
            }
        } else {
            return (
                <UncontrolledDropdown>
                    {actionMenuIcon ? (
                        <Tooltip title="Actions" arrow placement="bottom" PopperProps={{ style: { zIndex: 3 } }}>
                            <Box>
                                <DropdownToggle className="action-menu-icon">
                                    <Icon>{actionMenuIcon}</Icon>
                                </DropdownToggle>
                            </Box>
                        </Tooltip>
                    ) : (
                        <DropdownToggle color="action" size="sm" caret>
                            {dropdownText}
                        </DropdownToggle>
                    )}
                    <DropdownMenu>
                        {actionList.map((action, index) => {
                            if (action.divider) return <DropdownItem key={index} divider />;

                            if (action.to) {
                                if (action.external) {
                                    return (
                                        <DropdownItem key={index}>
                                            <div onClick={() => this.openExternal(action.to)}>{action.label}</div>
                                        </DropdownItem>
                                    );
                                }

                                return (
                                    <DropdownItem key={index}>
                                        <div onClick={() => this.props.history.push(action.to)}>{action.label}</div>
                                    </DropdownItem>
                                );
                            }

                            if (action.onClick) {
                                return (
                                    <DropdownItem key={index} onClick={action.onClick}>
                                        {action.label}
                                    </DropdownItem>
                                );
                            }
                        })}
                    </DropdownMenu>
                </UncontrolledDropdown>
            );
        }
    };

    renderHeader = () => {
        const { actions, styleOverride, renderHeader, title, isRequired, className, tableControls } = this.props;

        if (isFunction(renderHeader)) {
            return renderHeader();
        }

        const attrs = {
            className: classnames('card-header-title', className, {
                'non-field-required': isRequired
            })
        };

        return title || actions ? (
            <div data-cy="card-header" className="card-header" style={{ ...styleOverride, ...styleOverride?.header, background: '#F5F9FC', fontSize: '20px' }}>
                <div data-cy="card-header-title" {...attrs}>
                    {title}
                </div>
                <div data-cy="card-header-actions" className="card-header-actions">
                    {tableControls}
                    {this.renderActions()}
                </div>
            </div>
        ) : null;
    };

    renderBody = () => {
        const { styleOverride, renderBody } = this.props;

        if (isFunction(renderBody)) {
            return renderBody();
        }

        if (styleOverride) {
            return (
                <div className="card-body" style={{ ...styleOverride, ...styleOverride?.body }}>
                    {this.props.children}
                </div>
            );
        }

        return (
            <div className="card-body" style={{ fontSize: '14px' }}>
                {this.props.children}
            </div>
        );
    };

    renderFooter = () => {
        const { renderFooter } = this.props;

        if (isFunction(renderFooter)) {
            return renderFooter();
        }
    };

    render() {
        const { sameHeight = false, statBox = false, scrollableCard = false, styleOverride, id = '', twoColumn, left, right, className } = this.props;
        const emptyCard = this.props.children == null ? true : false;
        const addlClasses = className ? ' ' + className : '';

        return (
            <div
                id={id}
                className={
                    classnames('card', {
                        'same-height': sameHeight,
                        'stat-box': statBox,
                        'scrollable-card': scrollableCard,
                        'two-column-card': twoColumn,
                        'empty-card': emptyCard,
                        left: left,
                        right: right
                    }) + addlClasses
                }
                style={{ ...styleOverride, ...styleOverride?.card, background: '#F5F9FC', borderRadius: '12px', color: '#363836' }}
            >
                {this.renderHeader()}
                {this.renderBody()}
                {this.renderFooter()}
            </div>
        );
    }
}

export default withRouter(Card);
